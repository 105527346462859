import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import { checkToken } from 'utils/request';
import { UserRoles } from 'helpers/constants';
import useRouter from 'hooks/useRouter';
import { redirectToAppIfIsCoachDomain } from 'utils/subdomains';
import { ROUTES } from '../../constants';
import useAccount from 'hooks/useAccount';

const PublicRoute = ({ component: Component, authorized, onboardingStatus, isAdmin, ...rest }) => {
  const { query, domain } = useRouter();
  const { currentRole } = useAccount();
  const isCohealer = currentRole === UserRoles.cohealer;
  const onboarding = query.onboarding === 'true';

  return (
    <Route
      {...rest}
      render={p => {
        if (onboarding && authorized && !isCohealer) {
          redirectToAppIfIsCoachDomain(domain, ROUTES.ROLE_SWITCH);
          return;
        }
        if (!authorized) {
          return <Component {...p} />;
        }

        if (onboardingStatus === 'Registered') {
          return <Component {...p} />;
        }

        if (onboardingStatus === 'InfoAdded') {
          if (isAdmin) {
            return <Redirect to="/admin/contributions" />;
          }
          // if (p?.location?.pathname) {
          //   return <Redirect to="/account/billing/coach" />;
          // }

          const { from } = p.location.state || {
            from: { pathname: '/dashboard' },
          };
          return <Redirect to={from} />;
        }
      }}
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.node, PropTypes.elementType]).isRequired,
  authorized: PropTypes.bool.isRequired,
  onboardingStatus: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ account }) => ({
  authorized: checkToken(account?.token),
  onboardingStatus: account?.user?.onboardingStatus || '',
  isAdmin: account.roles.includes(UserRoles.admin), // current role
});

export default connect(mapStateToProps)(PublicRoute);
